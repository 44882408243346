
import { defineComponent } from "vue";
import ChevronRightIcon from "icons/ChevronRight.vue";

const __default__ = defineComponent({
  name: "MenuEntry",
  components: { ChevronRightIcon },
  props: {
    isEnabledAppendIcon: {
      type: Boolean,
      default: true,
    },
    version: {
      type: String,
      default: "",
    },
    isCheckBox: {
      type: Boolean,
      default: false,
    },
    borderBottomWidth: {
      type: String,
      default: "1px",
    },
    borderBottomColor: {
      type: String,
      default: "",
    },
    borderBottomStyle: {
      type: String,
      default: "dotted",
    },
  },
  data() {
    return {
      borderColor: this.borderBottomColor
        ? this.borderBottomColor
        : this.$isDarkMode()
        ? "var(--grey3-color)"
        : "var(--grey2-color)",
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "62ab3ef6": (_ctx.borderBottomWidth),
  "7df5783e": (_ctx.borderColor),
  "62782ee1": (_ctx.borderBottomStyle)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__