
import { defineComponent } from "vue";
import { useStore } from "vuex";
import BaseHeader2 from "@/components/BaseHeader2.vue";
import MenuEntry from "@/components/member/MenuEntry.vue";
import BaseImgCircle from "@/components/BaseImgCircle.vue";
import BottomSheet from "@/components/BottomSheet.vue";
import ShieldCheckIcon from "icons/ShieldCheck.vue";
import DefaultButton from "@/components/common/DefaultButton.vue";

export default defineComponent({
  name: "My",
  components: {
    BaseHeader2,
    MenuEntry,
    BaseImgCircle,
    BottomSheet,
    ShieldCheckIcon,
    DefaultButton,
  },
  data() {
    return {
      store: useStore(),
      showBS: false,
      certificate: false,
      isResended: false,
      nickname: "",
      email: "",
      thumbnail: "",
      status: false,
    };
  },
  computed: {
    isLogin: function (): boolean {
      return this.store.getters["member/isLogin"];
    },
    member(): any {
      return this.store.getters["member/member"];
    },
  },
  methods: {
    show() {
      this.showBS = true;
    },
    sendVerificationEmail() {
      let user = this.store.getters["member/member"];
      this.showBS = false;

      this.$axios
        .post(`/member/validate`, { email: user.email })
        .then(() => {
          this.isResended = true;
          this.$flutter.callHandler("selectGnb", {
            path: "/",
          });
        })
        .catch(() => {
          this.isResended = true;
        });
    },
  },
  beforeMount() {
    this.$flutter.callHandler("showGnb");
  },
  beforeUnmount() {
    this.$flutter.callHandler("hideGnb");
  },
  mounted() {
    if (this.isLogin) {
      this.$axios.get("/member/me").then((res) => {
        this.store.dispatch("member/updateToken", res.data);
      });
    }
  },
});
