import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1e1c5145"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "menu-entry" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = ["textContent"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ChevronRightIcon = _resolveComponent("ChevronRightIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass(!_ctx.isCheckBox ? 'is-not-checkbox' : 'is-checkbox')
    }, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ], 2),
    (_ctx.isEnabledAppendIcon)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_ChevronRightIcon, { size: 24 })
        ]))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "version",
          textContent: _toDisplayString(_ctx.version)
        }, null, 8, _hoisted_3))
  ]))
}