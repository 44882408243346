
import { defineComponent } from "vue";

const __default__ = defineComponent({
  props: {
    size: {
      type: String,
      default() {
        return "80px";
      },
    },
    src: String,
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "7fe26e83": (_ctx.size)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__